import React from 'react';
import './App.css';
import axios from 'axios';

const API_PATH = 'http://localhost:3002/send';

class Main extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ClientName: "",
            CaregiverName: "",
            ShiftDate: "",
            StartTime: "",
            EndTime: "",
            ServiceNotes: "",
            Breakfast: "",
            BreakfastNA: false,
            BreakfastPercent: '',
            AMSnack: "",
            AMSnackNA: false,
            AMSnackPercent: '',
            Lunch: "",
            LunchNA: false,
            LunchPercent: '',
            PMSnack: "",
            PMSnackNA: false,
            PMSnackPercent: '',
            Dinner: "",
            DinnerNA: false,
            DinnerPercent: '',
            NightSnack: "",
            NightSnackNA: false,
            NightSnackPercent: '',
            FluidIntake: "",
            UrineOutput: "",
            BowelMovement: "",
            ClientNameBG: "grey",
            CaregiverNameBG: "grey",
            ShiftDateBG: "grey",
            StartTimeBG: "grey",
            EndTimeBG: "grey",
            ServiceNotesBG: "grey",
            BreakfastBG: "grey",
            AMSnackBG: "grey",
            LunchBG: "grey",
            PMSnackBG: "grey",
            DinnerBG: "grey",
            NightSnackBG: "grey",
            FluidIntakeBG: "grey",
            UrineOutputBG: "grey",
            BowelMovementBG: "grey",

        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFormSubmission = this.handleFormSubmission.bind(this);
    }

    handleInputChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      const name = target.name;
      let newColor = target.value === "" ? "grey" : "pink";
      let hold = target.name + "BG";

      this.setState({ [name]: value , [hold]: newColor}); 
    }

    handleFormSubmission(event) {
        event.preventDefault();

        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: this.state
          })
            .then((response) => {
                if (response.data.status === 'success') {
                    alert("Message Sent.");
                    //this.resetForm();
                } 
                else if(response.data.status === 'fail') {
                    alert("Message failed to send.")
                }
            })
    }

    render() {
        return (
            <form onSubmit={this.handleFormSubmission} method="post">
                <div className="Section-margins">
                    <div className="Section-container">             
                        <div className="Header-cslContainer">
                            <strong className="Header-csl">
                            Caregiver Service Log
                            </strong>
                        </div>
                        <div className="Section-containerRow">
                            <div className="Header-labelContainer" style={{backgroundColor: this.state.ClientNameBG}}>
                                <label>
                                    CLIENT's Full Name:
                                </label> 
                            </div>
                            <div className="Header-inputContainer">
                                <input type="text" isRequired="yes"  className="Header-input" name="ClientName" value={this.state.ClientName} onChange={this.handleInputChange} />
                            </div>
                        </div>            
                        <div className="Section-containerRow">
                            <div className="Header-labelContainer" style={{backgroundColor: this.state.CaregiverNameBG}}>
                                <label>
                                    CAREGIVER's Full Name:  
                                </label>
                            </div>
                            <div className="Header-inputContainer">
                                <input type="text" className="Header-input" name="CaregiverName" value={this.state.CaregiverName} onChange={this.handleInputChange} /> 
                            </div>
                        </div>
                        <div className="Section-containerRow">
                            <div className="Header-labelContainer" style={{backgroundColor: this.state.ShiftDateBG}}>
                                <label>
                                    Shift Date: 
                                </label> 
                            </div>
                            <div className="Header-inputContainer">
                                <input type="date" value={this.state.ShiftDate} name="ShiftDate" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="Section-containerRow">
                            <div className="Header-labelContainer" style={{backgroundColor: this.state.StartTimeBG}}>
                                <label>
                                    Start Time:
                                </label>
                            </div>
                            <div className="Header-inputContainer">
                                <input type="time" value={this.state.StartTime} name="StartTime" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="Section-containerRow">
                            <div className="Header-labelContainer" style={{backgroundColor: this.state.EndTimeBG}}>
                                <label>
                                    End Time: 
                                </label>
                            </div>
                            <div className="Header-inputContainer" >
                                <input type="time" value={this.state.EndTime} name="EndTime" onChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>
                    <div className="Section-containerCol">
                        <div className="Section-header" style={{backgroundColor: this.state.ServiceNotesBG}}>
                            <h1>
                                Client Service Notes and Tasks Not Completed Explanations
                            </h1>
                        </div>
                        <textarea className="ClientServiceNotes" value={this.state.ServiceNotes} name="ServiceNotes" onChange={this.handleInputChange} />
                    </div>
                    <div className="Section-container">
                        <div className="Section-header">
                            <h1>
                                Meal Log: Document what was prepared and amount eaten
                            </h1>
                        </div>
                        <div className="Section-containerRow"> 
                            <div className="MealLog-labelContainer" style={{backgroundColor: this.state.BreakfastBG}}>
                                Breakfast
                            </div>
                            <div className="MealLog-inputContainer">
                                <div className="Section-containerRow">
                                    <textarea className="MealLog-textInput" name="Breakfast" value={this.state.Breakfast} onChange={this.handleInputChange}/>
                                    <label>
                                        N/A
                                        <input name="BreakfastNA" type="checkbox" checked={this.state.BreakfastNA} onChange={this.handleInputChange} />
                                    </label>
                                </div>
                                <div className="Section-containerRow">
                                    <label>
                                        % Eaten ...
                                        <select className="MealLog-percentSelect" name="BreakfastPercent" value={this.state.BreakfastPercent} onChange={this.handleInputChange}>
                                        <option value="0%">0%</option>
                                        <option value="25%">25%</option>
                                        <option value="50%">50%</option>
                                        <option value="75%">75%</option>
                                        <option value="100%">100%</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="Section-containerRow">
                            <div className="MealLog-labelContainer" style={{backgroundColor: this.state.AMSnackBG}}>
                                AM Snack
                            </div>
                            <div className="MealLog-inputContainer">
                                <div className="Section-containerRow">
                                    <textarea className="MealLog-textInput" name="AMSnack" value={this.state.AMSnack} onChange={this.handleInputChange}/>
                                    <label>
                                        N/A
                                        <input name="AMSnackNA" type="checkbox" checked={this.state.AMSnackNA} onChange={this.handleInputChange} />
                                    </label>
                                </div>
                                <div className="Section-containerRow">
                                    <label>
                                        % Eaten ...
                                        <select className="MealLog-percentSelect" name="AMSnackPercent" value={this.state.AMSnackPercent} onChange={this.handleInputChange}>
                                        <option value="0%">0%</option>
                                        <option value="25%">25%</option>
                                        <option value="50%">50%</option>
                                        <option value="75%">75%</option>
                                        <option value="100%">100%</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="Section-containerRow">
                            <div className="MealLog-labelContainer" style={{backgroundColor: this.state.LunchBG}}>
                                Lunch
                            </div>
                            <div className="MealLog-inputContainer">
                                <div className="Section-containerRow">
                                    <textarea className="MealLog-textInput" name="Lunch" value={this.state.Lunch} onChange={this.handleInputChange}/>
                                    <label>
                                        N/A
                                        <input name="LunchNA" type="checkbox" checked={this.state.LunchNA} onChange={this.handleInputChange} />
                                    </label>
                                </div>
                                <div className="Section-containerRow">
                                    <label>
                                        % Eaten__
                                        <select className="MealLog-percentSelect" name="LunchPercent" value={this.state.LunchPercent} onChange={this.handleInputChange}>
                                        <option value="0%">0%</option>
                                        <option value="25%">25%</option>
                                        <option value="50%">50%</option>
                                        <option value="75%">75%</option>
                                        <option value="100%">100%</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="Section-containerRow">
                            <div className="MealLog-labelContainer" style={{backgroundColor: this.state.PMSnackBG}}>
                                PM Snack
                            </div>
                            <div className="MealLog-inputContainer">
                                <div className="Section-containerRow">
                                    <textarea className="MealLog-textInput" name="PMSnack" value={this.state.PMSnack} onChange={this.handleInputChange}/>
                                    <label>
                                        N/A
                                        <input name="PMSnackNA" type="checkbox" checked={this.state.PMSnackNA} onChange={this.handleInputChange} />
                                    </label>
                                </div>
                                <div className="Section-containerRow">
                                    <label>
                                        % Eaten__
                                        <select className="MealLog-percentSelect" name="PMSnackPercent" value={this.state.PMSnackPercent} onChange={this.handleInputChange}>
                                        <option value="0%">0%</option>
                                        <option value="25%">25%</option>
                                        <option value="50%">50%</option>
                                        <option value="75%">75%</option>
                                        <option value="100%">100%</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="Section-containerRow">
                            <div className="MealLog-labelContainer" style={{backgroundColor: this.state.DinnerBG}}>
                                Dinner
                            </div>
                            <div className="MealLog-inputContainer">
                                <div className="Section-containerRow">
                                    <textarea className="MealLog-textInput" name="Dinner" value={this.state.Dinner} onChange={this.handleInputChange}/>
                                    <label>
                                        N/A
                                        <input name="DinnerNA" type="checkbox" checked={this.state.DinnerNA} onChange={this.handleInputChange} />
                                    </label>
                                </div>
                                <div className="Section-containerRow">
                                    <label>
                                        % Eaten__
                                        <select className="MealLog-percentSelect" name="DinnerPercent" value={this.state.DinnerPercent} onChange={this.handleInputChange}>
                                        <option value="0%">0%</option>
                                        <option value="25%">25%</option>
                                        <option value="50%">50%</option>
                                        <option value="75%">75%</option>
                                        <option value="100%">100%</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="Section-containerRow">
                            <div className="MealLog-labelContainer" style={{backgroundColor: this.state.NightSnackBG}}>
                                Night Snack
                            </div>
                            <div className="MealLog-inputContainer">
                                <div className="Section-containerRow">
                                    <textarea className="MealLog-textInput" name="NightSnack" value={this.state.NightSnack} onChange={this.handleInputChange}/>
                                    <label>
                                        N/A
                                        <input name="NightSnackNA" type="checkbox" checked={this.state.NightSnackNA} onChange={this.handleInputChange} />
                                    </label>
                                </div>
                                <div className="Section-containerRow">
                                    <label>
                                        % Eaten__
                                        <select className="MealLog-percentSelect" name="NightSnackPercent" value={this.state.NightSnackPercent} onChange={this.handleInputChange}>
                                        <option value="0%">0%</option>
                                        <option value="25%">25%</option>
                                        <option value="50%">50%</option>
                                        <option value="75%">75%</option>
                                        <option value="100%">100%</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="Section-containerRow">
                            <div className="MealLog-labelContainer" style={{backgroundColor: this.state.FluidIntakeBG}}>
                                Fluid Intake
                            </div>
                            <div className="MealLog-inputContainer">
                                <textarea className="MealLog-textInput" name="FluidIntake" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="Section-containerRow">
                            <div className="MealLog-labelContainer" style={{backgroundColor: this.state.UrineOutputBG}}>
                                Urine Output
                            </div>
                            <div className="MealLog-inputContainer">
                                <textarea className="MealLog-textInput" name="UrineOutput" onChange={this.handleInputChange} />
                            </div>
                        </div>
                        <div className="Section-containerRow">
                            <div className="MealLog-labelContainer" style={{backgroundColor: this.state.BowelMovementBG}}>
                                BM
                            </div>
                            <div className="MealLog-inputContainer">
                                <textarea className="MealLog-textInput" name="BowelMovement" onChange={this.handleInputChange} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <input type="submit" value="Submit Log"/>
                    </div>
                </div>
            </form>
      );
    }
  }

export default Main;